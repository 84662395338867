<template>
  <div style="height:100%">
 
<el-menu :default-active=activeIndex class="el-menu-vertical-demo el-aside" :collapse="isCollapse"  >
    <div style="display:flex; height:46px;padding:10px 0;   align-items: center; background:#1C1E39; 
    justify-content: space-between;">
            <img v-show="isCollapse==false" src="@/assets/logos.png" style="max-width:70%; height:46px;margin:0 auto "  alt="">
            <img v-show="isCollapse==true"  src="@/assets/logo2.png" style="max-width:80%; height:46px;margin:0 auto  "  alt="">
           
 
    </div>
     <router-link to="/index/sy"  style="color:#fff;     text-decoration: none;">
  <el-menu-item index="1">
       <i class="el-icon-s-home"> </i><span slot="title">首页</span>
  </el-menu-item>
        </router-link>
          <router-link to="/index/zhiwei" style="color:#fff;     text-decoration: none;">
  <el-menu-item index="2">
     

     <i class="el-icon-menu"></i><span slot="title">角色管理</span>
  </el-menu-item>
         </router-link>
           <router-link to="/index/dd" style="color:#fff;     text-decoration: none;">
 <el-menu-item index="3">
    
    <i class="el-icon-s-order"> </i><span slot="title">订单管理</span>

 
  </el-menu-item>
       </router-link>
        <router-link to="/index/cw" style="color:#fff;     text-decoration: none;">
  <el-menu-item index="4">
      
           <i class="el-icon-coin"></i><span slot="title">财务管理</span>

   
  </el-menu-item>
      </router-link>
       <router-link to="/index/pt" style="color:#fff;     text-decoration: none;">
   <el-menu-item index="5">
                   <i class="el-icon-setting"> </i><span slot="title">平台管理</span>
  </el-menu-item>
       </router-link>
        <router-link to="/index/hy" style="color:#fff;     text-decoration: none;">
    <el-menu-item index="6">
        
            <i class="el-icon-s-custom"></i><span slot="title">会员管理</span>

      
  </el-menu-item>
     </router-link>
 <div style="bottom:0%; width:100%;   position: absolute;">
                <i v-show="isCollapse==true"  @click="a" class="el-icon-s-unfold ic"  style="width:35px; font-weight: bold;padding:20px"></i>
                <i  v-show="isCollapse==false"  @click="b" class="el-icon-s-fold ic" style=" display:block;font-weight: bold;padding:20px;width:100%;    cursor: pointer;"><span style="margin-left:10px">收缩</span> </i>
               </div>
        
    
</el-menu>
 
  </div>
</template>

<script>
export default {
    data() {
      return {         
         activeIndex: '2',
        isCollapse: false,
        key:'1'
      };
    },
    methods: {
        a(){
            this.isCollapse= false  
             this.$emit('getTreeData',true);setTimeout(function (){
 
	window.location.reload();
}, 500)
         
        },
        b(){

           this.isCollapse= true  
            this.$emit('getTreeData',false);
            this.$route.go(0)
        },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    },
    created(){
        console.log(this.$route.meta.title)
           if( this.$route.meta.title ==undefined){
this.activeIndex = '1'
           }else{
             this.activeIndex= this.$route.meta.title
           }
            
    }
 
}
</script>

<style  scoped>
.ic:hover {
    outline: 0;
    background-color: #33375E;
}
.el-menu-item{
    color:#fff;
    font-size: 14px;
}

/deep/ .el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background-color: #33375E;
}
 .el-aside {
    background-color: #262946;
    color: #fff;
    
  height: 100%;
   overflow: hidden  !important;

  }
 .el-menu-item.is-active{
      background: #33375E !important;
   
  }
 .el-menu-item.is-active::before{
          content: "";
   
         left: 0;
    position: absolute;
    top:50%;
margin-top:-15px;
           border-left: 2px solid #409EFF;
           height: 30px;
      
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 240px;
    min-height:100%;
  }
/deep/ .el-radio-button:first-child .el-radio-button__inner{
border:none !important
}  

/deep/ .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled){
    background: none;
    box-shadow:none
}
</style>